import "./chat.css";
import { Navbar } from "../../Components/Navbar/navbar";
import { io } from "socket.io-client";
import { Component } from "react";
import ScrollToBottom from 'react-scroll-to-bottom';


export class Chat extends Component {
  constructor() {
    super();

    this.state = {
      messages: [
        {
          name: "System 🛠️",
          message:
            "Messages should start appearing below once the lecture is live :)",
        },
      ],
      rooms: [],
      allChat: true,
    };
    this.getRooms = this.getRooms.bind(this);
  }

  getRooms() {
    const roomString = window.location.href.split("/")[3];
    if (roomString.length < 9) return;
    console.log(roomString.slice(8, roomString.length).split("-"));
    this.setState(
      {
        rooms: roomString.slice(4, roomString.length).split("-"),
      },
      () => {
        if (this.state.rooms.length == 0) this.setState({ allChat: true });
        else this.setState({ allChat: false });
      }
    );
  }

  componentDidMount() {
    this.getRooms();
    const socket = io("wss://chat.rabel.in", { transports: ["websocket"] });
    socket.on("message", (data) => {
      const jsonD = JSON.parse(data);
      if (jsonD.message == undefined || jsonD.message == "") return;
      if (this.state.allChat == true) {
        let c = this.state.messages;
        c.push({
          name: jsonD.name,
          message: jsonD.message,
        });
        this.setState({
          messages: c,
        });
      } else {
        if (this.state.rooms.indexOf(jsonD.room) === -1) return;
        let c = this.state.messages;
        c.push({
          name: jsonD.name,
          message: jsonD.message,
        });
        this.setState({
          messages: c,
        });
      }
    });
  }

  render() {
    return (
      <div className="main">
        <div className="navbarDiv">
          <Navbar />
        </div>
        <div className="chatWrapper">
          <div className="chat">
              <ScrollToBottom className="containerWrapper">
                {this.state.messages.map((e, key) => {
                  return (
                    <div className="container" key={key}>
                      <h4 className="chatTextName">{e.name}</h4>
                      <h4 className="chatTextMessage">{e.message}</h4>
                    </div>
                  );
                })}
              </ScrollToBottom>
          </div>
        </div>
      </div>
    );
  }
};