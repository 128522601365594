import React from "react";
import "./navbar.css";
import pwlogo from '../../Assets/pwlogo.png';
import {useNavigate} from "react-router-dom"

export const Navbar = (props) => {

  const navigate = useNavigate();

  return (
    <div className="navbar">
      <img src={pwlogo} className="pwlogo" />
      <div className="navbarGrid">
        <h1 className="menuStack" onClick={()=>navigate("/setup")}>Setup</h1>
        <h1 className="whiteText">Logout</h1>
      </div>
    </div>
  );
};
