import './App.css';
import { Component } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Chat } from './Pages/Chat/chat';
import { Setup } from './Pages/Setup/setup';

class App extends Component {
  constructor() {
    super();

    this.state = {
    }
  }

  render() {
    return (
      <div>
        <Router>
          <Routes>
            <Route path='/chat' element={ <Chat/> }/>
            <Route path='/setup' element={ <Setup/> }/>
            {/* <Route path='/setup' element={ <Login/> }/> */}
          </Routes>
        </Router>
      </div>
    );
  }

}

export default App;